<template>
  <div id="login_page">
    <div class="login_box">
      <p>Welcome to login the service for call center system</p>
      <a-form
        layout="vertical"
        :model="loginForm"
        :rules="rules"
        name="login"
        ref="login_form"
        scrollToFirstError
      >
        <a-form-item label="Account Number" name="account">
          <a-input v-model:value="loginForm.account" />
        </a-form-item>
        <a-form-item label="Password" name="password">
          <a-input-password v-model:value="loginForm.password" />
        </a-form-item>
        <a-form-item style="margin-top: 50px">
          <a-button
            size="large"
            type="primary"
            style="width: 100%"
            @click="tologin"
            >Sign in</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        account: null,
        password: null,
      },
      rules: {
        account: [
          {
            required: true,
            message: 'Please fill in Account Number',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please fill in Password',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  mounted() {},

  methods: {
    tologin() {
      this.$refs.login_form
        .validate(['account', 'password', 'login'])
        .then((res) => {
          this.$store
            .dispatch('userLogin', this.loginForm)
            .then((res) => {
              this.$message.success('Login successful!')
              return Promise.resolve()
            })
            .then((res) => {
              this.$router.push('/home')
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
#login_page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url('../../images/login_bg.png');
  .login_box {
    width: 550px;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    // opacity: 0.9;
    padding: 30px 50px;
    border-radius: 15px;
    box-shadow: 1px 1px 20px 1px #e9e9e9;
    > p {
      color: blank;
      font-size: 25px;
      font-weight: blob;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
</style>
